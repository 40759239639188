@import 'src/assets/styles/variables.scss';

.Footer-wrap {
  background: $prime-color;
  padding: 30px 0;

  .footer-underline {
    border-top: 1px solid white;
    width: 70vw;
    margin: 0 auto;

    p {
      color: white;
      text-align: center;
      margin: 30px 0 0;
      font-size: 12px;
    }
  }

  .Footer {
    width: 70vw;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      color: #fff;
      font-size: 18px;
    }

    a,
    .address {
      text-decoration: none;
      font-weight: 300;
      font-size: 15px;
      line-height: 30px;
      color: #ffffff;
    }

    .info-footer {
      max-width: 360px;

      p {
        color: #fff;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        text-indent: 0;
        margin: -10px 0 0 0;
      }
    }

    .nav-footer {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 20px;

        a:hover {
          border-bottom: 1px solid white;
        }
      }

      div {
        margin: 3px 0;
      }
    }

    .contact-footer {
      max-width: 250px;

      .social-links-icons {
        display: flex;
        align-items: center;

        img {
          width: 25px;
        }

        div {
          margin: 1vh 1vw 0 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Footer {
    .nav-footer {
      display: none;
    }
  }
}
