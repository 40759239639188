@import 'src/assets/styles/variables.scss';

.Header {
  position: fixed;
  left: 0;
  right: 0;
  background: transparent;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  transition: background 0.2s ease-in;
  z-index: 10;

  &.scrolling {
    background: $prime-color;
  }

  .phone-link {
    text-decoration: none;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
  }

  .mobile-header {
    display: none;

    .menu-button {
      height: auto;
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .line-btn {
        height: 3px;
        width: 100%;
        background: white;
      }

      .line-btn:nth-child(2) {
        margin: 1vh 0;
      }
    }
  }

  .active {
    color: #b3c7ff;
  }
  ul {
    list-style: none;

    li {
      display: inline-block;
      padding-right: 30px;
      font-weight: 300;
      font-size: 16px;

      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .header-language {
    button {
      font-weight: 300;
      font-size: 12px;
      color: #ffffff;
      background: transparent;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    background: $prime-color;
    display: block;

    .menu,
    .header-language,
    .phone-link {
      display: none;
    }

    .mobile-header {
      display: flex;
    }

    .header-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .menu-wrap {
    nav {
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        li {
          margin: 2vh 0;
          font-size: 24px;
          padding: 0;
        }
      }
    }

    .header-lang-mob {
      display: flex;
      justify-content: center;
      .lang-btn {
        margin: 2vh 0;
        font-size: 24px;
        color: #ffffff;
        background: transparent;
        border: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}