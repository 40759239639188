@import 'src/assets/styles/variables.scss';

.first-block-wrap {
  background-image: url('../assets/images/backgrounds/home.jpg');
  background-size: cover;
  height: 100vh;

  .first-block {
    width: 70vw;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #ffffff;

    h1 {
      text-transform: uppercase;
    }

    p {
      color: #ffffff;
      width: 50%;
    }
  }
}

.about-block-wrap {
  width: 100%;
  margin-bottom: 2.6em;
  background-size: contain;
  background-position: 30%;

  .about-block {
    padding: 30px 0;
    width: 70vw;
    margin: auto;

    p {
      margin: 0 0 15px;
      color: black;
    }

    .about-question {
      font-weight: normal;
    }

    .title-block {
      .line-title {
        background-color: black;
      }
    }

    .about-p {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 40px;

      div {
        padding: 25px;
        border-radius: 20px;
        box-shadow: 0 0 40px rgba(36, 74, 152, 0.2);
      }

      .about-title {
        color: $prime-color;
        font-weight: bold;
        text-indent: 0;
      }

      .about-text {
        text-indent: 0;
      }
    }
  }
}

.how-we-can-help-wrap {
  background-image: url(../assets/images/backgrounds/help.jpg);
  background-size: cover;
  padding: 20px 0;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 50px;
  }

  .how-we-can-help {
    width: 70vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 3fr;

    .line-title {
      background-color: #ffffff;
    }

    ul {
      list-style-type: none;

      li {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        .icon {
          margin-right: 30px;
          font-size: 70px;
        }
      }
    }

    .shift {
      margin-left: 30px;
    }

    .list {
      font-weight: bold;
      color: #ffffff;
      text-indent: 0;
      font-size: 22px;
      margin: 0;
    }

    .text {
      color: #ffffff;
      font-size: 16px;
      margin: 0;
      text-indent: 0;
      text-align: left;
    }
  }
}

.servises-we-offer-wrap {
  background-image: url(../assets/images/backgrounds/services.jpg);
  background-size: cover;
  height: auto;
  padding-bottom: 5vh;
  color: black;

  .servises-we-offer {
    width: 70vw;
    margin: 0 auto;

    .line-title {
      background-color: black;
    }

    .content-block {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .text {
        font-size: 15px;
        color: white;
      }

      .description {
        padding: 25px;
        border-radius: 20px;
        background-position: center;
        background-color: white;
        box-shadow: 0 0 40px rgba(36, 74, 152, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 320px;

        .list {
          font-weight: bold;
          display: none;
        }

        .arrows {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          color: $prime-color;
          font-weight: bold;

          .icon {
            cursor: pointer;

            &:hover {
              color: blueviolet;
            }

            &.disable {
              color: #d2d2d2;
              cursor: unset;
            }
          }
        }

        p {
          color: black;
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        cursor: pointer;

        div {
          display: flex;
          align-items: center;

          .icon {
            font-size: 25px;
          }

          .list {
            color: black;
            font-weight: bold;
            text-indent: 20px;
          }
        }
      }
    }
  }
}

.tech-wrap {
  padding: 50px 0;

  .tech {
    width: 70vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
    gap: 40px;

    .tech-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      h3 {
        margin: 0;
        font-weight: normal;
      }

      .tech-btn {
        padding: 10px;
        border-radius: 20px;
        width: 70px;
        border: 2px solid $prime-color;
        background-color: transparent;
        color: $prime-color;
        font-weight: bold;
        cursor: pointer;

        &.active {
          background-color: $prime-color;
          color: white;
        }

        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    .title-block {
      display: flex;
      align-items: center;
    }

    .tech-list {
      display: flex;
      flex-flow: row wrap;
      gap: 10px;
      max-width: 510px;

      .tech-card {
        border-radius: 10px;
        background-color: #f1f2f6;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100px;
        height: 80px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .first-block-wrap {
    .first-block {
      width: auto;
      padding: 0 30px;
      text-align: center;
      margin: 0;
      justify-content: center;
      align-items: center;

      p {
        width: 100%;
      }
    }
  }

  .tech-wrap {
    padding: 0 0 50px;

    .tech {
      width: auto;
      padding: 0 30px;
      display: block;

      .tech-content {
        .tech-list {
          max-width: 100%;
        }

        .tech-btn {
          margin-bottom: 10px;
        }

        h3 {
          display: none;
        }
      }
    }
  }

  .about-block-wrap {
    .about-block {
      width: auto;
      padding: 0 30px;

      .about-p {
        grid-template-columns: 1fr;
      }
    }
  }

  .how-we-can-help-wrap {
    display: block;
    min-height: auto;

    h2 {
      font-size: 38px;
    }

    .how-we-can-help {
      width: auto;
      padding: 0 30px;
      grid-template-columns: 1fr;

      ul {
        padding: 0;
      }
    }
  }

  .servises-we-offer-wrap {
    .servises-we-offer {
      width: auto;
      padding: 0 30px;

      .content-block {
        grid-template-columns: 1fr;

        .description {
          min-height: 340px;

          .list {
            display: block;
          }
        }

        ul {
          display: none;
        }
      }
    }
  }
}
