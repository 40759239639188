@import 'src/assets/styles/variables.scss';

.Contacts {
  background: url(../assets/images/backgrounds/contacts.jpg);
  background-size: cover;
  padding-top: 70px;

  .title-wrap {
    .title {
      .title-block {
        color: white;
        padding-bottom: 20px;
      }
    }
  }

  .contact-block-wrap {
    width: 70vw;
    margin: 0 auto;

    .contact-block {
      display: grid;
      grid-template-columns: 1fr 2fr;

      .contact-info {
        color: white;

        h3 {
          margin-top: 30px;
        }

        .info-subsection {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 20px;

          img {
            width: 30px;
            height: 30px;
          }

          p {
            color: #fff;
            margin: 0;
          }

          a {
            display: block;
            text-decoration: none;
            color: #fff;

            &:not(:last-of-type) {
              margin-bottom: 3px;
            }
          }
        }
      }

      .contact-mobile {
        display: none;
        width: 100%;

        align-items: center;
        justify-content: center;
        text-align: center;

        input,
        textarea,
        button {
          width: 90vw;
          padding: 0;
          margin: 1vh 1vw;
        }

        input,
        button {
          height: 5vh;
        }

        textarea {
          height: 17vh;
        }

        input::placeholder {
          padding-left: 2vw;
        }

        button {
          background: $prime-color;
          border-radius: 4px;
          font-weight: 300;
          font-size: 14px;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
}

.contact-form {
  max-width: 500px;
  background: white;
  color: black;
  text-align: center;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 60px;

  form {
    p {
      margin: 10px 0;
      font-size: 16px;
    }

    .stretched {
      grid-area: stretched;
    }

    .input-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      grid-template-areas:
            ". ."
            ". ."
            "stretched stretched"
    }

    .input_message {
      display: flex;
    }

    .input-item {
      display: flex;
      position: relative;

      .error {
        color: red;
        position: absolute;
        bottom: -18px;
        font-size: 14px;
      }
    }

    input {
      flex-grow: 1;
      border: none;
      border-bottom: 1px solid #606060;
      padding: 10px;
      color: black;
    }

    textarea {
      border: 1px solid #606060;
      color: black;
      width: 100%;
      padding: 10px;
      min-height: 60px;
    }

    input::placeholder {
      color: #99a8e2;
      font-weight: 100;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.05em;
    }

    input:focus,
    textarea:focus {
      outline: none;
    }

    .input-title {
      padding: 10px;
      text-align: left;
    }

    .btn-contact {
      display: flex;

      .contact-submit {
        flex: 1;
        margin-top: 20px;
        padding: 5px;
        background: $prime-color;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        cursor: pointer;

        &.disabled {
          cursor: unset;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Contacts {
    .contact-block-wrap {
      width: auto;
      padding: 0 30px;

      .contact-block {
        grid-template-columns: 1fr;

        .contact-form {
          margin: 0 auto 30px;
          max-width: unset;

          form {
            .input-wrap {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  .contact-form {
    form {
      .input-wrap {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
