@import 'src/assets/styles/variables.scss';

.Industries {
  background-color: #f3f3f3;

  ul {
    background-image: url('../assets/images/industry-tabs/modal-industry.png');
    background-size: cover;
    height: auto;
    width: 700px;
    padding: 40px;
    border-radius: 30px;
    list-style-type: none;

    .project-title {
      font-weight: bold;
    }

    .project-desc:not(:last-of-type) {
      margin-bottom: 20px;
    }

    li {
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
    }
  }

  .Industries-wrap {
    height: 100vh;
    background-image: url('../assets/images/backgrounds/industries.jpeg');
    background-size: cover;
    padding-top: 70px;
    display: flex;
    align-items: center;

    .Industries-we-offer {
      width: 70vw;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin: 0 0 30px 0;
        color: white;
      }

      p {
        width: 50%;
      }

      .Industries-par {
        margin: 0;
        max-width: 880px;
        padding: 0 0 50px 0;
        color: white;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  .industies-block {
    width: 70vw;
    margin: 0 auto;
    padding: 50px 0;

    .industies-item {
      padding: 50px;
      cursor: pointer;
      background-color: white;
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 30px;
      min-height: 200px;
      &:not(:last-of-type) {
        margin-bottom: 50px;
      }

      .industies-title {
        text-align: center;
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .explore-arrow {
          color: $prime-color;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
          margin-top: 30px;

          svg {
            padding-top: 4px;
          }
        }

        p {
          margin: 0;
        }
      }
    }

    .industies-item:hover {
      box-shadow: 0 2.7673px 2.2138px rgba(0, 0, 0, .02), 0 41.7776px 33.4221px rgba(0, 0, 0, .05), 0 100px 80px rgba(0, 0, 0, .07);

      & .explore-arrow {
        color: blueviolet;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Industries {
    ul {
      width: 80%;
    }

    .Industries-wrap {
      .Industries-we-offer {
        width: auto;
        padding: 0 30px;

        h4 {
          text-align: center;
        }

        .Industries-par {
          width: 100%;
        }
      }
    }

    .industies-block {
      width: auto;
      padding: 30px 30px;

      .industies-item {
        grid-template-columns: 1fr;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }

        img {
          display: none;
        }
      }
    }
  }
}

